import Router, { useRouter } from 'next/router';

import type { NextPage } from 'next';

const Home: NextPage = () => {
  const router = useRouter();
  Router.push({
    pathname: '/exchange',
    query: router.query,
  });

  return null;
};

export default Home;
